// src/StockAnalysisPage.js
import React from 'react';
import Navbar from './Navbar';

const StockAnalysisPage = () => {
  return (
    <div className="page-container">
      <Navbar />
      <h1>Stock Analysis Page</h1>
      <p>Here you can analyze specific stocks.</p>
    </div>
  );
};

export default StockAnalysisPage;
