import React, { useState } from 'react';
import { auth, firestore } from './firebase/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import Navbar from './Navbar'; // 네비게이션 바 추가
import './StockPage.css';

const StockPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [models, setModels] = useState([]);

  const handleSearch = async () => {
    try {
      // 입력된 검색어를 소문자로 변환하여 Firestore에서 검색
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const modelsRef = collection(firestore, `models/TRADE/${lowerCaseSearchTerm}`);
      const querySnapshot = await getDocs(modelsRef);
      const modelsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setModels(modelsList);
    } catch (error) {
      console.error("Error fetching models: ", error);
    }
  };

  const handleLogout = () => {
    auth.signOut().catch((error) => {
      console.error("Error during sign out: ", error);
    });
  };

  return (
    <div className="stock-container">
      <Navbar /> {/* 네비게이션 바 추가 */}
      <h1>M-AI QUANT</h1>

      {/* 티커 검색 기능 추가 */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search Ticker"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button">Search</button>
      </div>

      {/* 검색 결과로 모델 리스트 표시 */}
      <ul className="model-list">
        {models.map(model => (
          <li key={model.id} className="model-item">
            <span className="ticker">{searchTerm}</span>
            <span className="name">{model.name}</span>
            <span className="monthly-return">{model.recent_return}%</span>
            <span className="subscription-price">${model.subscription_price}</span>
          </li>
        ))}
      </ul>

      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default StockPage;
