import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebase/firebaseConfig';
import LoginPage from './LoginPage';
import StockPage from './StockPage';
import SimulationPage from './SimulationPage';
import StockAnalysisPage from './StockAnalysisPage';
import RecommendationPage from './RecommendationsPage';

const ProtectedRoute = ({ user, children }) => {
  return user ? children : <Navigate to="/login" />;
};

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={user ? <Navigate to="/stocks" /> : <LoginPage />} />
        <Route path="/stocks" element={<ProtectedRoute user={user}><StockPage /></ProtectedRoute>} />
        <Route path="/simulation" element={<ProtectedRoute user={user}><SimulationPage /></ProtectedRoute>} />
        <Route path="/analysis/stocks" element={<ProtectedRoute user={user}><StockAnalysisPage /></ProtectedRoute>} />
        <Route path="/recommendations" element={<ProtectedRoute user={user}><RecommendationPage /></ProtectedRoute>} />
        
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
