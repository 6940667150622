// src/StockAnalysisPage.js
import React from 'react';
import Navbar from './Navbar';

const RecommendationsPage = () => {
  return (
    <div className="page-container">
      <Navbar />
      <h1>Recommendations Page</h1>
      <p>Here you can recommend or analysis company or tickers.</p>
    </div>
  );
};

export default RecommendationsPage;
