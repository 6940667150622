// src/firebase/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC8v4Nicor3IEHxQ3lyOIvsMA5Tcz2dgfg",
    authDomain: "maiquant-f9717.firebaseapp.com",
    databaseURL: "https://maiquant-f9717-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "maiquant-f9717",
    storageBucket: "maiquant-f9717.appspot.com",
    messagingSenderId: "364669184187",
    appId: "1:364669184187:web:b424a25a4a7817864ba19e",
    measurementId: "G-J76KBJLNF5"
  };

  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);
  const db = getDatabase(app);
  const firestore = getFirestore(app);
  
  export { auth, db, firestore };