// src/LoginPage.js
import React from 'react';
import { auth } from './firebase/firebaseConfig';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import './LoginPage.css';
import googleLogo from './assets/google-login-logo.png';

const LoginPage = () => {
  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Error during sign in: ", error);
    });
  };

  return (
    <div className="login-container">
      Inspiration Platform for your trade.
      <h1>M-AI QUANT</h1>
      <button className="google-login-button" onClick={handleLogin}>
        Sign in with Google      </button>
    </div>
  );
  /*
  return (
    <div className="login-container">
      <h1>M-AI QUANT</h1>
      <button className="google-login-button" onClick={handleLogin}>
        <img src={googleLogo} className="google-logo" alt="Google logo" />
      </button>
    </div>
  );*/
};

export default LoginPage;
