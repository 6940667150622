import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaChartLine, FaLightbulb, FaCog } from 'react-icons/fa'; // 아이콘 패키지 사용
import './Navbar.css';

const Navbar = () => {
  return (
    <>
      {/* 최상단 바 */}
      <div className="top-bar">
        <div className="top-bar-left">
          <h1 className="top-bar-logo">M-AI QUANT</h1>
        </div>
        <div className="top-bar-right">
          <Link to="/settings">
            <FaCog className="settings-icon" />
          </Link>
        </div>
      </div>

      {/* 하단 네비게이션 바 */}
      <nav className="bottom-navbar">
        <ul className="bottom-navbar-menu">
          <li>
            <Link to="/" className="nav-link">
              <FaHome className="nav-icon" />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/trade" className="nav-link">
              <FaChartLine className="nav-icon" />
              <span>Trade</span>
            </Link>
          </li>
          <li>
            <Link to="/recommendations" className="nav-link">
              <FaLightbulb className="nav-icon" />
              <span>Recommendations</span>
            </Link>
          </li>
          <li>
            <Link to="/analysis" className="nav-link">
              <FaChartLine className="nav-icon" />
              <span>Investment Analysis</span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
